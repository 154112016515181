import { Link } from '@remix-run/react';
import { useLocation } from '@remix-run/react';
import { useRef, useState } from 'react';

import { TriangleArrowDown } from '../../components/icons/Arrows';
import { useOutsideClick } from '../../hooks/useOutsideClick';

export type NavItem = string | Record<string, string>;

function NavTab(props: { title: string; item: NavItem }): JSX.Element {
  const { title, item } = props;
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => setOpen(false));

  if (typeof item === 'string') {
    const isActive = location.pathname.startsWith(item);
    return (
      <Link
        to={item}
        className={`text-sm font-medium tracking-[1.25px] text-white border-b-2 ${
          isActive ? 'border-white' : 'border-transparent'
        }`}
      >
        {title}
      </Link>
    );
  }

  const isActive = !!Object.values(item).find((i) =>
    location.pathname.startsWith(i)
  );

  return (
    <div ref={ref} className='text-yellow-50 relative whitespace-nowrap'>
      <div
        className={`flex items-center justify-start cursor-pointer text-sm font-medium tracking-[1.25px] text-white border-b-2 ${
          isActive ? 'border-white' : 'border-transparent'
        }`}
        onClick={() => setOpen(!open)}
      >
        <div className='mr-2'>{title}</div>
        <TriangleArrowDown />
      </div>
      {open && (
        <div className='min-w-full flex flex-col absolute top-8 border-2 border-secondary rounded-lg z-45 bg-black whitespace-nowrap'>
          {Object.keys(item).map((key: string, index: number) => (
            <Link
              key={index}
              to={item[key]}
              className={`h-10 flex items-center border-b last:border-b-0 border-secondary text-sm ${
                location.pathname.startsWith(item[key]) ? 'font-semibold' : ''
              }`}
              onClick={() => setOpen(false)}
            >
              <div className='mx-2'>{key}</div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}

export function Header(props: { links: Record<string, NavItem> }): JSX.Element {
  const { links } = props;
  return (
    <div className='flex items-center justify-start gap-7.5 xl:gap-10'>
      {Object.keys(links).map((title: string, index: number) => (
        <NavTab key={index} title={title} item={links[title]} />
      ))}
    </div>
  );
}
